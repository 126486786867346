export const environment = {
  name: 'Dev',
  production: false,
  baseServerUrl: 'https://dev.api.cratebase.io',
  apiBaseUrl: 'https://dev.api.cratebase.io/api/v1',
  apiAdminBaseUrl: 'https://dev.api.cratebase.io',
  sentryDSN: '',
  userAgent: 'Cratebase/0.0.4+https://dev.api.cratebase.io',
  postNordUrl: '',
  postNordTrackingUrl: '',
  mixpanelToken: '9c9fff2aeb226c46e393e4421665c917',
  version: require('../../package.json').version,
  shopify_site_api_version: '2023-04',
  postnlTrackingUrl: 'https://jouw.postnl.nl/track-and-trace',
};
