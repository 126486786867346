{
  "name": "cratebase-web",
  "version": "2.0.0",
  "type": "module",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "dev": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "lint": "ng lint",
    "build:dev": "ng build -c dev",
    "build:production": "ng build -c production",
    "deploy": "node deploy.js",
    "pretty-quick": "pretty-quick",
    "prettier-format-all": "prettier --write .",
    "prepare": "husky install",
    "openapi-generate": "tsx ./src/openapi-generate.ts"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.1",
    "@angular/cdk": "^19.2.2",
    "@angular/common": "^19.2.1",
    "@angular/compiler": "^19.2.1",
    "@angular/core": "^19.2.1",
    "@angular/forms": "^19.2.1",
    "@angular/material": "^19.2.2",
    "@angular/platform-browser": "^19.2.1",
    "@angular/platform-browser-dynamic": "^19.2.1",
    "@angular/router": "^19.2.1",
    "@ctrl/ngx-emoji-mart": "^9.2.0",
    "@juggle/resize-observer": "^3.4.0",
    "@ng-select/ng-select": "^14.2.0",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngx-translate/core": "^16.0.0",
    "@ngx-translate/http-loader": "^16.0.1",
    "@primeng/themes": "^19.0.10",
    "@sentry/angular": "^8.51.0",
    "@types/chart.js": "^2.9.41",
    "chart.js": "^4.4.7",
    "chartjs-adapter-moment": "^1.0.1",
    "chartjs-plugin-datalabels": "^2.2.0",
    "country-code-lookup": "^0.1.3",
    "dayjs": "^1.11.13",
    "include-media": "^1.4.10",
    "mixpanel-browser": "^2.59.0",
    "ngx-extended-pdf-viewer": "^22.3.9",
    "ngxtension": "^4.3.2",
    "normalize.css": "^8.0.1",
    "openapi-fetch": "^0.13.4",
    "pdf-lib": "^1.17.1",
    "primeng": "^19.0.10",
    "print-js": "^1.6.0",
    "rxjs": "~7.8.1",
    "shipengine": "^1.0.1",
    "svg-country-flags": "^1.2.10",
    "swiper": "^7.2.0",
    "uuid": "^11.0.5",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.1",
    "@angular/cli": "^19.2.1",
    "@angular/compiler-cli": "^19.2.1",
    "@types/mixpanel-browser": "^2.51.0",
    "@types/node": "^20.14.10",
    "autoprefixer": "^10.4.20",
    "aws-sdk": "^2.913.0",
    "husky": "^7.0.0",
    "jasmine-core": "^3.9.0",
    "karma": "^6.3.4",
    "karma-chrome-launcher": "^3.1.0",
    "karma-coverage": "^2.0.3",
    "karma-jasmine": "^4.0.1",
    "karma-jasmine-html-reporter": "^1.7.0",
    "openapi-typescript": "^7.6.1",
    "postcss": "^8.4.40",
    "prettier": "^2.3.0",
    "pretty-quick": "^3.1.0",
    "tailwindcss": "^3.4.7",
    "tsx": "^4.19.2",
    "typescript": "~5.7.3"
  },
  "engines": {
    "node": ">=20.14.0"
  }
}
